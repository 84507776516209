<template>
  <div>
    <v-dialog v-model="variation_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="variation_dialog = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Variations</v-toolbar-title>
        </v-toolbar>
        <div style="padding: 50px">
          <v-data-table :headers="variation_headers" :items="variations" :items-per-page="10" class="elevation-1 mt-4">
            <template v-slot:body="{ items }">
              <tbody v-if="!loading_variations">
                <tr
                  v-for="variant in items"
                  :key="variant.id"
                  :set="(variant_attributes = JSON.parse(variant.attributes))"
                >
                  <td>
                    <span
                      v-for="(variation_value, key, index) in variant_attributes.variations"
                      :key="`variation-value-${key}-${variant.id}`"
                    >
                      &nbsp; {{ index > 0 ? '-' : '' }} &nbsp; <strong>{{ key }}: </strong>{{ variation_value }}
                    </span>
                  </td>
                  <td>{{ variant.sku }}</td>
                  <td>{{ variant.price }}</td>
                  <td>
                    {{ variant.stock_quantity > 900 ? '900+' : variant.stock_quantity }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6" class="text-center" style="padding: 80px 0px">
                    <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>

    <v-container>
      <v-card style="padding: 20px">
        <div v-if="is_feed_loading" class="text-center">
          <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="80px" />
        </div>
        <div v-else>
          <v-row>
            <v-col cols="4"> <strong>Product List Name: </strong> {{ feed.title }} </v-col>
            <v-col cols="4"> <strong>Etsy Category: </strong> {{ feed.remote_category }} </v-col>
            <v-col cols="4" class="text-center">
              <v-btn :disabled="initiating_upload" @click="reUploadProducts()" color="info" class="tet-white" small>
                <span v-if="!initiating_upload"> Re-upload {{ total_products }} Products </span>
                <span v-else>Initiating Feed Upload...</span>
              </v-btn>
              <br />
              <v-btn
                :disabled="initiating_upload"
                @click="forcedReUploadProducts"
                color="red"
                dark
                class="text-white mt-2"
                small
              >
                <span v-if="!initiating_upload"> Force Re-upload Feed</span>
                <span v-else>Initiating Feed Upload...</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mb-4 mt-4"></v-divider>
          <v-row>
            <v-col cols="6" sm="3" class="text-center">
              <h4>Active Products</h4>
              <span style="font-size: 35px">{{ product_counts.active_count }}</span>
            </v-col>

            <v-col cols="6" sm="3" class="text-center">
              <h4>Inactive Products</h4>
              <span style="font-size: 35px">{{ product_counts.inactive_count }}</span>
            </v-col>

            <v-col cols="6" sm="3" class="text-center">
              <h4>Draft Products</h4>
              <span style="font-size: 35px">{{ product_counts.draft_count }}</span>
            </v-col>

            <v-col cols="6" sm="3" class="text-center">
              <h4>Failed Products</h4>
              <span style="font-size: 35px">{{ product_counts.error_count }}</span>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <v-data-table
        :search="search_keyword"
        :headers="headers"
        :items="feed_products"
        :items-per-page="10"
        class="elevation-1 mt-4"
      >
        <template v-slot:top>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="search_keyword"
              label="Search Keyword"
              outlined
              dense
              placeholder="Search Keyword"
              hide-details
            ></v-text-field>
          </v-col>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="!is_feed_product_loading">
            <tr v-for="item in items" :key="item.id">
              <td><img width="70px" :src="getProductImage(item.attributes)" alt="" /></td>
              <td>{{ item.title }}</td>
              <td>{{ item.sku }}</td>
              <td>
                <div :set="(etsy_product = item.etsy_product)">
                  <div v-if="!etsy_product">
                    <span class="dpl_badge dpl_badge_secondary">N/A</span>
                  </div>
                  <div v-else>
                    <!-- <span
                      v-if="etsy_product.etsy_state == 'upload-failed' || etsy_product.is_uploaded == 0"
                      class="dpl_badge dpl_badge_danger"
                      >Upload Failed</span
                    > -->
                    <v-chip
                      v-if="etsy_product.etsy_state == 'upload-failed' || etsy_product.is_uploaded == 0"
                      small
                      class="ma-2"
                      color="red"
                      text-color="white"
                      >Upload failed</v-chip
                    >
                    <div v-else>
                      <div v-if="etsy_product.etsy_state == 'draft'">
                        <!-- <span
                          class="product_draft-badge dpl_badge dpl_badge_warning dpl_mB-1"
                          style="display: lnline-block !important"
                          >Draft</span
                        > -->
                        <v-chip small class="ma-2" color="primary" text-color="white">Draft</v-chip>
                      </div>
                      <div v-if="etsy_product.etsy_state == 'active'">
                        <span class="dpl_badge dpl_badge_success-dark">Active</span>
                      </div>
                      <div v-if="etsy_product.etsy_state == 'edit' || etsy_product.etsy_state == 'inactive'">
                        <span class="dpl_badge dpl_badge_danger">Inactive</span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td v-html="renderUploadStatus(item)"></td>

              <td>
                <v-btn
                  title="Show Variations"
                  fab
                  @click="showVariations(item.id)"
                  small
                  v-if="item.parent_id == 0 && item.variant_id == 0"
                  class="table-action--button"
                >
                  <v-icon small dark>{{ icons.mdiChevronDown }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="6" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import store from '@/store'
import { mdiChevronDown, mdiClose } from '@mdi/js'
export default {
  data() {
    return {
      feed_id: null,
      shop: null,
      feed: {},
      feed_products: [],
      search_keyword: null,
      product_counts: {
        uploaded_count: 0,
        error_count: 0,
        active_count: 0,
        draft_count: 0,
        inactive_count: 0,
      },
      filters: {
        state: '',
      },
      is_feed_loading: false,
      is_feed_product_loading: false,
      total_products: null,

      headers: [
        {
          text: 'Image',
        },
        { text: 'Title', value: 'title', sortable: false },
        { text: 'SKU', value: 'sku', sortable: false },
        { text: 'Etsy Status' },
        { text: 'Remarks' },
        { text: 'Actions', value: 'actions' },
      ],

      icons: {
        mdiChevronDown,
        mdiClose,
      },

      variation_dialog: false,
      loading_variations: false,
      variations: [],
      variation_headers: [
        {
          text: 'Attributes',
        },
        { text: 'SKU' },
        { text: 'Price' },
        { text: 'Stock' },
      ],

      initiating_upload: false,
    }
  },
  mounted() {
    this.shop = store.getters.loggedInShop
    this.feed_id = this.$route.params.feed_id
    this.getFeedDetails()
    this.getFeedProducts()
  },
  methods: {
    async getFeedDetails() {
      try {
        this.is_feed_loading = true
        let response = await axios.get(`admin/shops/${this.shop.id}/feed/${this.feed_id}/get-details`)
        if (response.data.success) {
          this.feed = response.data.feed
        }
      } catch (e) {
      } finally {
        this.is_feed_loading = false
      }
    },

    async getFeedProducts() {
      try {
        this.is_feed_product_loading = true
        let url_params = {
          state: this.filters.state,
        }
        let response = await axios.get(`admin/shops/${this.shop.id}/feed/${this.feed_id}/get-products`, {
          params: url_params,
        })

        if (response.data.success) {
          if (this.total_products == null) {
            this.total_products = response.data.products.total
          }
          this.feed_products = response.data.products
          if (response.data.feed_product_counts) {
            this.product_counts = response.data.feed_product_counts
          }
        }
      } catch (e) {
      } finally {
        this.is_feed_product_loading = false
      }
    },

    getProductImage(attributes) {
      attributes = JSON.parse(attributes)
      console.log(attributes)
      let img_url = ''
      if (attributes.image_url) {
        if (Array.isArray(attributes.image_url)) {
          if (attributes.image_url[0]) {
            img_url = attributes.image_url[0]
          }
        } else {
          img_url = attributes.image_url
        }
      }
      return img_url
    },

    renderUploadStatus(product) {
      let etsy_product = product.etsy_product

      if (!etsy_product) {
        return `<span class='dpl_badge dpl_badge_secondary'>Not Uploaded</span>`
      }

      if (etsy_product.etsy_state == 'deleted') {
        return `<span class='dpl_error_text'>You previously deleted this product from Etsy.`
      } else if (etsy_product.etsy_state == 'upload-failed' || etsy_product.is_uploaded == 0) {
        return product.etsy_product.upload_error_message
      } else {
        return `<span class="ma-2 v-chip theme--light v-size--small green white--text"><span class="v-chip__content">Uploaded</span></span>`
      }
    },

    async showVariations(product_id) {
      try {
        this.variation_dialog = true
        this.loading_variations = true
        let response = await axios.get(`admin/shops/${this.shop.id}/shopify-product/${product_id}/variations`)
        if (response.data.success) {
          this.variations = response.data.variations
        }
      } catch (e) {
      } finally {
        this.loading_variations = false
      }
    },

    forcedReUploadProducts() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          html: 'You want to force-upload the feed? <br> <br> All the products inside the feed will get affected',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) this.reUploadProducts(true)
        })
    },

    async reUploadProducts(is_forced = false) {
      try {
        this.initiating_upload = true
        let response = await axios.post(`admin/shops/feed/${this.feed_id}/upload-products-to-etsy`, {
          shop_id: this.shop.id,
          is_forced: is_forced,
        })
        if (response.data.success) {
          this.$swal.fire({
            icon: 'success',
            title: 'Done',
            text: 'Feed upload initiated and is running on background.',
          })
        }
      } catch (e) {
      } finally {
        this.initiating_upload = false
      }
    },
  },
}
</script>